/** @type {import('tailwindcss').Config} */
module.exports = {
  content: [
    '.src/**/*.{html,js,php}', 
    './*.php', 
    './templates/**/*.php',
    './**/*.php',
  ],
  theme: {
    fontFamily: {
      'sans': ['ABCReproVariable', 'sans-serif'],
    },
    extend: {
      colors: {
        ot: {
          1: "var(--color-ot1)",
          2: "var(--color-ot2)", 
          3: "var(--color-ot3)", 
          4: "var(--color-ot4)",
          5: "var(--color-ot5)", 
          6: "var(--color-ot6)", 

          7: "var(--color-ot7)", 
          8: "var(--color-ot8)", 
          9: "var(--color-ot9)", 
          10: "var(--color-ot10)", 
          11: "var(--color-ot11)", 
          12: "var(--color-ot12)",
          13: "var(--color-ot13)",
          14: "var(--color-ot14)", 
          15: "var(--color-ot15)" 
        },
      },
    },
  }
}
