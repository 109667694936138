import $ from "jquery";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import iro from "@jaames/iro";
import resolveConfig from "tailwindcss/resolveConfig";
import tailwindConfig from "../../tailwind.config.js";

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

window.jQuery = $;
window.$ = $;

const APP = (function () {
  $(document).ready(() => {
    init();
  });
  var thePicker;
  const fullConfig = resolveConfig(tailwindConfig);

  function init() {
    // console.log('🚀');
    passwordProtection();
    positionPasswordForm(true);

    $(window).on("resize", () => {
      positionPasswordForm();
    });
  }

  function positionPasswordForm(animate = false) {
    const md = fullConfig.theme.screens.md;

    if (window.innerWidth < md.replace("px", "")) {
      $("#password-form").css({
        top: "",
        left: "",
      });

      if (animate) {
        gsap.to("#password-form", { opacity: 1, duration: 0.5 });
      }

      return;
    }

    let indicator = $("#logowrap").find("rect#posihelper");
    $("#password-form").css({
      top: indicator.position().top,
      left: indicator.position().left,
    });

    if (animate) {
      gsap.to("#password-form", { opacity: 1, duration: 0.5 });
    }
  }

  function passwordProtection() {
    console.log("🗝️");
    $("#password-form").submit(function (event) {
      event.preventDefault();
      var password = $("#password-input").val();
      $.ajax({
        type: "POST",
        url: "/password-protect",
        data: { password: password },
        dataType: "json",
        success: function (data) {
          // console.log(data);
          if (data.error) {
            alert(data.error);
          } else {
            $("#topBar").html(data.header);
            $("#protected-content").html(data.content);

            loginTransition();
            colorpicker();
            colorform();
          }
        },
        error: function (xhr, status, error) {
          console.log(error);
        },
      });
    });
  }

  function loginTransition() {
    const headerColors = $("#topBar .h-full");
    const logoWrapper = $("#logowrap");
    const textSection = $("#text");
    const loginTL = gsap.timeline({
      paused: true,
      onComplete: () => {
        openMobileMenu();
        scrollAction();
        gallery();
        footerAction();
        logoAction();
      },
    });

    loginTL.to("#password-form", { opacity: 0, yPercent: -50, duration: 0.4 });
    loginTL.to(headerColors, { opacity: 1, duration: 0.5, stagger: 0.2 }, 0);
    loginTL.to(logoWrapper, { height: "auto", duration: 1 }, 0.2);
    loginTL.fromTo(
      textSection,
      { y: 100, opacity: 0 },
      { y: 0, opacity: 1, duration: 1 },
      0.2
    );

    loginTL.play();
  }

  function openMobileMenu() {
    const button = $("#toggleMenu");
    const menuTL = gsap.timeline({
      paused: true,
      reversed: true,
      onReverseComplete: () => {
        gsap.set("#mobileMenu", { display: "none" });
      },
    });
    menuTL.set("#mobileMenu", { display: "block" });
    menuTL.to("#toggleMenu span", { rotate: 45, duration: 0.3 });
    menuTL.to("#mobileMenu div", { opacity: 1, stagger: 0.1, duration: 0.5 });

    button.on("click", () => {
      if (menuTL.reversed()) {
        menuTL.play();
      } else {
        menuTL.reverse();
      }
    });
  }

  function scrollAction() {
    $(".scrollme[data-target]").on("click", function () {
      let target = $(this).attr("data-target");
      gsap.to(window, {
        scrollTo: {
          y: "#" + target,
          offsetY: 50,
        },
        duration: 1,
      });
    });
  }

  function gallery() {
    const images = gsap.utils.toArray(".fitimage");

    images.forEach((img, index) => {
      let picture = $(img).find("img");
      gsap.set(img, {
        y: $(img).height() * -1,
      });

      const tl = gsap.timeline({ paused: true });

      // Option 1 Scale Stack Effect
      /*
      tl.set(picture, {
        scaleY: 0, 
      });
      tl.to(picture,{
        scaleY: 1,
        ease: 'none'
      });

      ScrollTrigger.create({
        trigger: img,
        start: 'bottom bottom',
        endTrigger: '#separator',
        end: 'top bottom',
        scrub: true,
        pin: true,
        pinSpacing: false,
        markers:true,
      });
      
      ScrollTrigger.create({
        trigger: img,
        start: 'bottom bottom',
        end: '+=' + $(img).height(),
        scrub: true,
        animation: tl,
        toggleActions: 'play none none reverse',
      });
      */

      // Option 2 Liporello Effect

      tl.set(img, {
        scaleY: 0,
      });
      tl.to(img, {
        scaleY: 1,
        ease: "none",
      });

      const sc = ScrollTrigger.create({
        trigger: img,
        start: "bottom bottom",
        end: "+=" + $(img).height(),
        scrub: true,
        pin: true,
        pinSpacing: false,
        // anticipatePin:1.2,
        animation: tl,
        toggleActions: "play none none reverse",
        invalidateOnRefresh: true,
      });

      $(window).one("scroll", () => {
        sc.refresh();
        // console.log('🤫');
      });
    });
  }

  function colorform() {
    var form = document.getElementById("submitColors");
    var message = document.getElementById("colorFormMessages");
    var fields = {};
    form.querySelectorAll("[name]").forEach(function (field) {
      fields[field.name] = field;
    });

    // Displays all error messages and adds 'error' classes to the form fields with
    // failed validation.
    var handleError = function (response) {
      var errors = [];
      for (var key in response) {
        if (!response.hasOwnProperty(key)) continue;
        if (fields.hasOwnProperty(key)) fields[key].classList.add("error");
        Array.prototype.push.apply(errors, response[key]);
      }
      message.innerHTML = errors.join("<br>");
    };

    var onload = function (e) {
      if (e.target.status === 200) {
        message.innerHTML = "Success!";
      } else {
        handleError(JSON.parse(e.target.response));
      }
    };

    var submit = function (e) {
      e.preventDefault();
      console.log(e.target);
      var request = new XMLHttpRequest();
      request.open("POST", e.target.action);
      request.onload = onload;
      request.send(new FormData(e.target));
      // Remove all 'error' classes of a possible previously failed validation.
      for (var key in fields) {
        if (!fields.hasOwnProperty(key)) continue;
        fields[key].classList.remove("error");
      }
    };
    form.addEventListener("submit", submit);
  }

  function footerAction() {
    ScrollTrigger.create({
      trigger: "footer",
      start: "bottom bottom",
      end: "+=" + $(window).height(),
      scrub: true,
      pin: true,
      pinSpacing: true,
    });
  }

  function colorpicker() {
    thePicker = new iro.ColorPicker("#picker", {
      // handleSvg: '#handle',
      // handleProps: { x: 10, y: 10 },
      width: 500,
      boxHeight: 200,
      borderWidth: 0,
      borderRadius: 0,
      colors: ["rgb(255,255,255)", "rgb(255,255,255)", "rgb(255,255,255)"],
      layout: [
        {
          component: iro.ui.Box,
          options: {
            boxHeight: 200,
          },
        },
        {
          component: iro.ui.Slider,
          options: {
            id: "hue-slider",
            sliderType: "hue",
            boxHeight: 4,
          },
        },
      ],
    });

    const colorList = document.getElementById("colorList");

    // https://iro.js.org/guide.html#color-picker-events
    thePicker.on(["mount", "color:change"], function () {
      colorList.innerHTML = "";
      thePicker.colors.forEach((color) => {
        const index = color.index;
        const hexString = color.hexString;
        colorList.innerHTML += `
          <li>
            <div onClick="APP.setColor(${index})" class="swatch" style="background: ${hexString}"></div>
            <button type="button" class="button clearcolor" onClick="APP.clearColor(${index})"><span>+</span></button>
          </li>
            `;
        $("#submitColors")
          .find('input[type="hidden"]')
          .eq(index)
          .val(hexString);
      });
    });

    resizePicker();
  }

  function setColor(colorIndex) {
    // setActiveColor expects the color index!
    thePicker.setActiveColor(colorIndex);
  }

  function clearColor(colorIndex) {
    const swatches = $("#colorList .swatch");
    const newColors = [];

    swatches.each((index, swatch) => {
      newColors.push($(swatch).css("background-color"));
    });

    newColors[colorIndex] = "rgb(255,255,255)";
    thePicker.setColors(newColors);

    $("#colorList li")
      .eq(colorIndex)
      .find(".swatch")
      .css({ background: "#ffffff" });
    $("#submitColors").find('input[type="hidden"]').eq(colorIndex).val("");
  }

  function resizePicker() {
    if (!thePicker) return;
    let medium = fullConfig.theme.screens.lg;
    let gapValue = $("#formwrap").css("gap");
    let gapInPx = parseFloat(gapValue.replace("px", ""));

    if (medium.replace("px", "") > $(window).width()) {
      console.log("smaller md");
      var size = $("#formwrap").width();
    } else {
      var size = $("#formwrap").width() / 2 - gapInPx / 2;
    }

    thePicker.resize(size);
  }

  function logoAction() {
    const logoTL = gsap.timeline({
      paused: true,
      defaults: {
        duration: 1,
        ease: "circ.inOut",
        yoyoEase: true,
      },
    });
    const logo = $("#BOOOOOK");
    const o = logo.find("#Vector_8");
    const k = logo.find("#Vector_7");

    let o1 = o.clone();
    o1.attr("class", "nr1");
    let o2 = o.clone();
    o1.attr("class", "nr2");
    let o3 = o.clone();
    o1.attr("class", "nr3");
    logo.append(o1).append(o2).append(o3);

    logoTL
      .to(o1, { xPercent: 98 }, 0)
      .to(o2, { xPercent: 196 }, 0)
      .to(o3, { xPercent: 294 }, 0)
      .to(k, { xPercent: 225 }, 0);

    $("#logowrap")
      .on("mouseenter", () => {
        logoTL.play();
      })
      .on("mouseleave", () => {
        logoTL.reverse();
      });
  }

  return {
    setColor: setColor,
    clearColor: clearColor,
    thePicker: thePicker,
    resizePicker: resizePicker,
  };
})();

window.APP = APP;

$(window).on("resize", () => {
  APP.resizePicker();
});
